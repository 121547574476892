import { AxiosResponse } from 'axios';
import {getFederateIdToken, initCSRFToken, initCSRFTokenForBackend, logout} from '../api/api';
import { TenantConfigProps } from '../api/type';
import {getGatewayBackendAxiosInstance} from "../api/axios";

declare global {
  interface Window {
    sancus: {
      checkIfUiElementAuthorized: (uiResource: string[]) => Promise<AxiosResponse<any, any>>;
      initCSRFTokenForBackend: (backendDomain: string) => Promise<AxiosResponse<any, any>>;
      getFederateIdToken: () => Promise<AxiosResponse<any, any>>;
      logout: () => void;
      fetch: any;
      env: {
        backendBaseUrl: string;
      }
    },
    sancusInternal: {
      tenantConfig: TenantConfigProps;
    },
    trustedTypes: {
      createPolicy: (...p: any) => any;
    }
  }
}

export const registerAuthZ = (config: any) => {
  const { checkIfUiElementAuthorized, backendBaseUrl } = config;
  window.sancus = window.sancus || { env: {} };
  window.sancus.checkIfUiElementAuthorized = checkIfUiElementAuthorized;
  window.sancus.env.backendBaseUrl = backendBaseUrl;
};

export const registerServer = () => {
  window.sancus = window.sancus || { env: {} };
  window.sancus.getFederateIdToken = getFederateIdToken;
  window.sancus.initCSRFTokenForBackend = initCSRFTokenForBackend;
  window.sancus.logout = logout;
};

export const registerTenantConfig = (tenantConfig: TenantConfigProps) => {
  window.sancusInternal = window.sancusInternal || { tenantConfig: {} };
  window.sancusInternal.tenantConfig = tenantConfig;
};

// The only use case of eval in EC admin portal: forms use eval to return a function.
function isEvalReturnFunction(functionToEval: string) {
  const pattern = /^(\(function anonymous\(\$root)(.*?)/;
  const match = pattern.exec(functionToEval);
  return match !== null;
}

const DISALLOW_TAGS = [
  'script',
];

// Verification here will prevent injecting script element or elements with 'src' attribute
export function isSafeHtml(htmlToInject: string) {
  const illegalTags = DISALLOW_TAGS
    .map((tag) => `<${tag}[^>]*>`)
    .join('|');

  const illegalPattern = new RegExp(`${illegalTags}`);
  return !illegalPattern.test(htmlToInject);
}

function isURLInAllowList(url: string): boolean {
  try {
    const urlObj = new URL(url);
    if (window.location.host === new urlObj.host) {
      return true;
    }
    if (window.sancusInternal && window.sancusInternal.tenantConfig) {
      if (new URL(window.sancusInternal.tenantConfig.assetsEntryUrl).host === urlObj.host) {
        return true;
      }
      for (const allowedOrigin of (window.sancusInternal.tenantConfig.cspAllowedOrigins || [])) {
        if (new URL(allowedOrigin).host === urlObj.host) {
          return true;
        }
      }
    }
    return false;
  } catch (e) {
    // if url is invalid, it means an api path, so return true
    return true;
  }
}

/**
 * This function will create a default trusted policy.
 * Reference: https://www.w3.org/TR/trusted-types/#default-policy-hdr
 * @See: https://code.amazon.com/reviews/CR-104073447
 */
export function setDefaultTrustPolicy() {
  if (window.trustedTypes) {
    const { trustedTypes } = window;
    trustedTypes.createPolicy('default', {
      /**
       * Ensure we only eval known strings.
       * @reference https://www.w3.org/TR/trusted-types/#trusted-script
       * @param scriptToEval
       */
      createScript: (scriptToEval: string) => {
        /**
         * In the known usage scenarios of eval/new Function, eval is used to return a function.
         * Step 1: Use eval to generate function
         * Step 2: Call this function using user input as parameter
         * In step 1, it does not rely on any user input, which means that the content of eval will not contain any user input.
         * In step 2, the running environment is a normal JS execution environment (not eval), so no user input will be executed.
         */
        if (isEvalReturnFunction(scriptToEval)) {
          return scriptToEval;
        }
        throw new Error('Illegal eval! reference: https://www.w3.org/TR/trusted-types/#html-injection-sinks');
      },
      /**
       * Currently, we don't use any HTML injection in our application code, but it's used in the Polaris dependency (AWS React Components lib)
       * Violation code: https://github.com/cloudscape-design/components/blob/73ce333aa6aeee360c1119b1e1d57549e905d112/src/icon/internal.tsx#L111
       * @reference https://www.w3.org/TR/trusted-types/#html-injection-sinks
       * @param htmlToInject
       */
      createHTML: (htmlToInject: string) => {
        if (isSafeHtml(htmlToInject)) {
          return htmlToInject;
        }
        throw new Error(
          'Illegal HTML injection operation! reference: https://www.w3.org/TR/trusted-types/#html-injection-sinks',
        );
      },
      // Reference: https://www.w3.org/TR/trusted-types/#dom-xss-injection-sinks
      createScriptURL: (_url: string) => {
        if (isURLInAllowList(_url)) {
          return _url;
        }
        throw new Error(
          'Illegal DOM injection operation! reference: https://www.w3.org/TR/trusted-types/#dom-xss-injection-sinks',
        );
      },
    });
  }
}

export function overrideXHRForInitCSRF() {
  const originalSend = XMLHttpRequest.prototype.send;
  let csrfTokenLoaded = false;
  let csrfTokenLoading: boolean = false;
  XMLHttpRequest.prototype.send = function (data) {
    if (!csrfTokenLoaded && !csrfTokenLoading) {
      csrfTokenLoading = true;
      initCSRFToken().then(() => {
        console.log('CSRF token loaded');
        csrfTokenLoaded = true;
        csrfTokenLoading = false;
        originalSend.call(this, data);
      }).catch((error) => {
        console.log('CSRF token failed to load', error);
        csrfTokenLoading = false;
        originalSend.call(this, data);
      });
    } else {
      originalSend.call(this, data);
    }
  };
}

export function overrideXHRAndFetchToAddSessionId() {
  (function() {
    const originalSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (data) {
      const sessionId = localStorage.getItem("sancus#sessionId");
      if (sessionId) {
        this.setRequestHeader('X-SANCUS-SESSION-ID', sessionId);
      }
      originalSend.call(this, data);
    };
  })();

  (function() {
    const oldFetch = window.fetch;
    window.fetch = function(resource, options) {
      const sessionId = localStorage.getItem("sancus#sessionId");
      if (sessionId) {
        options = options || {};
        const headers = new Headers(options.headers || {});
        headers.append('X-SANCUS-SESSION-ID', sessionId);
        options.headers = headers;
      }
      return oldFetch(resource, options);
    };
  })();

}

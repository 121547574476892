// @ts-ignore
// import { getEnv } from '../env';

if (!window.Amazon) {
  // @ts-ignore
  window.Amazon = {
    IDP: {
      config: {},
    },
  };
}

// https://w.amazon.com/index.php/Sentry/Regionalized_Identity/Migration/JavaScript#Optional_Config_Settings
// The host of web page is not protected by midway, so we need to exclude it.
// @ts-ignore
window.Amazon.IDP.config.excludeDomains = [window.location.host];

// @ts-ignore
// Set the default value to false unless we need to enable the feature according to the response from server.
window.Amazon.IDP.config.shouldAuthenticate = (): boolean => false;

// window.Amazon.IDP.config.periodicRefresh = true; //refreshes the midway token by calling the /sso/login page.

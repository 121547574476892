import axios, { AxiosResponse } from 'axios';
import { getEnv } from '../env';

/**
 * Replace state with current page path to make sure user can be redirected to current page after login
 */
const replaceMidwayAuthNRedirectURL = (loginURL: string):string => {
  const url = new URL(loginURL);
  if (url.searchParams.has('state')) {
    url.searchParams.set('state', btoa(JSON.stringify({
      path: window.location.pathname + window.location.search,
    })));
  }
  return url.toString();
};

export const getWebServerAxiosInstance = async () => {
  const env = await getEnv();
  const webServerAxiosInstance = axios.create({
    baseURL: env.serverEndpoint,
    timeout: 25000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  webServerAxiosInstance.interceptors.request.use((config) => config, (error) => Promise.reject(error));

  webServerAxiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      const { response } = error;
      if (response.status === 401) {
        const { data } = response;
        const { loginURL, authType, sessionId } = data;
        localStorage.setItem("sancus#sessionId", sessionId);
        window.location.href = replaceMidwayAuthNRedirectURL(loginURL);
      } else {
        console.error("unexpected error when requesting web server:", error);
      }
      return Promise.reject(error);
    },
  );
  return webServerAxiosInstance;
};

export const getGatewayBackendAxiosInstance =  () => {
  const gatewayBackendAxiosInstance = axios.create({
    baseURL: window.sancus.env.backendBaseUrl,
    timeout: 25000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  gatewayBackendAxiosInstance.interceptors.request.use((config) => config, (error) => Promise.reject(error));

  return gatewayBackendAxiosInstance;
};

export const getCustomAxiosInstance = async (baseUrl: string) => {
  const customBackendAxiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 25000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  customBackendAxiosInstance.interceptors.request.use((config) => config, (error) => Promise.reject(error));

  return customBackendAxiosInstance;
};

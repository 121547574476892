/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {getCustomAxiosInstance, getGatewayBackendAxiosInstance, getWebServerAxiosInstance} from './axios';
import { TenantConfigProps } from './type';

export const getTenantConfigApi = async () : Promise<AxiosResponse<TenantConfigProps>> => {
  const currentUrl = new URL(window.location.href);
  let additionalParams = {};
  if (currentUrl.searchParams.has('code') && currentUrl.searchParams.has('state')) {
    additionalParams = {
      code: currentUrl.searchParams.get('code'),
      state: currentUrl.searchParams.get('state'),
    };
  }
  if (currentUrl.searchParams.has('id_token') && currentUrl.searchParams.has('state')) {
    additionalParams = {
      id_token: currentUrl.searchParams.get('id_token'),
      state: currentUrl.searchParams.get('state'),
    };
  }
  const sessionId = localStorage.getItem("sancus#sessionId");
  const additionalHeaders = {};
  if (sessionId) {
    additionalHeaders['X-SANCUS-SESSION-ID'] = sessionId;
  }
  return (await getWebServerAxiosInstance()).get(`/getTenantConfig?${new URLSearchParams(additionalParams)}`, {
    headers: additionalHeaders,
  });
};

export const logout = async () => {
  await (await getWebServerAxiosInstance()).get('/logout');
};

export const getFederateIdToken = async () => (await getWebServerAxiosInstance()).get('/getFederateIdToken');

export const initCSRFToken = async () => getGatewayBackendAxiosInstance().get('/initCSRFToken');

export const initCSRFTokenForBackend = async (baseUrl: string) => ((await getCustomAxiosInstance(baseUrl)).get('/initCSRFToken'))

// get current stage
interface Env {
  stage: string;
  serverEndpoint: string;
  gatewayEndpoint: string;
}

let env: Env | null = null;

export const getEnv = async (): Promise<Env> => {
  if (env) {
    return env;
  }
  const response = await fetch('/settings.json', {
    credentials: 'same-origin'
  });
  if (response.status === 200) {
    env = await response.json();
    if (env === null) {
      throw new Error('Failed to get environment configuration: env is null');
    }
    return env;
  }
  throw new Error(`Failed to get environment configuration: ${response.statusText}`);
};
